/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'

export function AsideMenuMain() {
  const intl = useIntl()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/svg/dashboard-icons/Community.svg'
        title='Comunity'
      />

      {/* ADMIN MENU */}
      {user && user.role === 'admin' && (
        <>
          <div className='menu-item'>
            <div className='menu-content'>
              <div className='separator mx-1 my-4'></div>
            </div>
          </div>
          <div className='menu-item'>
            <div className='menu-content pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
            </div>
          </div>
          <AsideMenuItemWithSub
            to='/admin/survey'
            title='Survey'
            icon='/media/svg/dashboard-icons/Products.svg'
          >
            <AsideMenuItem to='/admin/survey' title='View By Topic' hasBullet={true} />
            <AsideMenuItem
              to='/admin/survey/surveyTopicsByProduct'
              title='View By Product'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to='/admin/product'
            title='Product'
            icon='/media/svg/dashboard-icons/Products.svg'
          >
            <AsideMenuItem to='/admin/product/all' title='View All Products' hasBullet={true} />
            <AsideMenuItem to='/admin/product/icon' title='Product Icons' hasBullet={true} />
          </AsideMenuItemWithSub>
          <AsideMenuItem
            to='/admin/collection'
            title='Collections'
            icon='/media/svg/dashboard-icons/Collections.svg'
          ></AsideMenuItem>
          <AsideMenuItem
            to='/admin/topic'
            title='Topics'
            icon='/media/svg/dashboard-icons/Collections.svg'
          ></AsideMenuItem>
          <AsideMenuItem
            to='/admin/faq'
            title='FAQs'
            icon='/media/svg/dashboard-icons/Collections.svg'
          ></AsideMenuItem>
          <AsideMenuItemWithSub
            to='/admin/ingredient'
            title='Ingredients'
            icon='/media/svg/dashboard-icons/Collections.svg'
          >
            <AsideMenuItem
              to='/admin/ingredient/all'
              title='View By Ingredients'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/admin/ingredient/family/all'
              title='View By Family'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </>
      )}
      {/* END ADMIN MENU */}

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

      <div className='menu-item'>
        <div className='menu-content pb-2'>
          <span className='menu-section text-uppercase fs-8 ls-1'>FORMULAS</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/product'
        title='Products'
        icon='/media/svg/dashboard-icons/Products.svg'
      >
        <AsideMenuItem to='/product' title='All Products' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/collection/all'
        title='Collections'
        icon='/media/svg/dashboard-icons/Collections.svg'
      ></AsideMenuItem>
      <AsideMenuItem
        to='/ingredient'
        title='Ingredients'
        icon='/media/svg/dashboard-icons/Ingredients.svg'
      ></AsideMenuItem>

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

      <div className='menu-item'>
        <div className='menu-content pb-2'>
          <span className='menu-section text-uppercase fs-8 ls-1'>ENGAGE</span>
        </div>
      </div>

      <AsideMenuItem
        to='/insights'
        title='Insights'
        icon='/media/svg/dashboard-icons/LatestInsights.svg'
      ></AsideMenuItem>

      <AsideMenuItemWithSub
        to='/vote'
        title='Vote'
        icon='/media/svg/dashboard-icons/Vote.svg'
      ></AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/propose'
        title='Propose questions'
        icon='/media/svg/dashboard-icons/ProposeQuestions.svg'
      ></AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

      <div className='menu-item'>
        <div className='menu-content pb-2'>
          <span className='menu-section text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/chat'
        title='Chat'
        icon='/media/svg/dashboard-icons/Chat.svg'
      ></AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/forum'
        title='Forum'
        icon='/media/svg/dashboard-icons/Forum.svg'
      ></AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

      <div className='menu-item'>
        <div className='menu-content pb-2'>
          <span className='menu-section text-uppercase fs-8 ls-1'>About</span>
        </div>
      </div>

      <AsideMenuItemWithSub
        to='/eternal'
        title='Codeage Eternal'
        icon='/media/logos/codeage-favicon-green.png'
        type='img'
      ></AsideMenuItemWithSub>

      <AsideMenuItem
        to='https://blog.codeage.com'
        title='Blog'
        icon='/media/svg/dashboard-icons/Blog.svg'
        isExternal={true}
      ></AsideMenuItem>

      <AsideMenuItemWithSub to='/legal' title='Legal' icon='/media/svg/dashboard-icons/Legal.svg'>
        <AsideMenuItem
          to='https://www.codeage.com/policies/terms-of-service'
          title='Terms & conditions'
          hasBullet={true}
          isExternal={true}
        />
        <AsideMenuItem
          to='https://www.codeage.com/policies/privacy-policy'
          title='Privacy policy'
          hasBullet={true}
          isExternal={true}
        />
        <AsideMenuItem
          to='https://www.codeage.com/pages/california-privacy-notice'
          title='Your California privacy rights'
          hasBullet={true}
          isExternal={true}
        />
        <AsideMenuItem
          to='https://www.codeage.com/pages/ccpa-compliance'
          title='CCPA requests'
          hasBullet={true}
          isExternal={true}
        />
        <AsideMenuItem
          to='https://www.codeage.com/pages/gdpr-compliance'
          title='GDPR requests'
          hasBullet={true}
          isExternal={true}
        />
        <AsideMenuItem
          to='https://accounts.accessibe.com/statement?domain=codeage.com'
          title='Accessibility statement'
          hasBullet={true}
          isExternal={true}
        />
        <AsideMenuItem
          to='https://www.codeage.com/policies/refund-policy'
          title='Refunds & returns'
          hasBullet={true}
          isExternal={true}
        />
      </AsideMenuItemWithSub>
    </>
  )
}
